import { template as template_3876d9924db049dcaa0e6c5d1a497403 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_3876d9924db049dcaa0e6c5d1a497403(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
